/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Container } from 'react-bootstrap';

import CookieConsent from "react-cookie-consent";

import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <div
        id="main"
        style={{
          margin: `0 auto`,
        }}
      >
        <main>{children}</main>
        <CookieConsent
          style={{backgroundColor: "rgb(0, 164, 228, 0.93)", color: "#212529"}}
          location="bottom"
          buttonText="Accept"
          cookieName="300sharks-gdpr-google-analytics"
          enableDeclineButton
          declineButtonText="Reject"
        >
          <p style={{fontSize: 16}}><strong>300sharks.com x Cookies</strong></p>
          <p style={{fontSize: 16}}>Our website uses cookies to give you the best possible UX. You can decide if you want to accept or reject them. More information can be found under our <Link style={{color:"#212529"}} to="/privacy-policy">Privacy Policy</Link>.</p>
        </CookieConsent>
        <footer style={{textAlign: "center"}}>
          <Container>
          © {new Date().getFullYear()}, 300 Sharks. All rights reserved. Built in Munich with open source goodness. | <Link style={{color: "#212529"}} to="/imprint">Imprint</Link> | <Link style={{color: "#212529"}} to="/privacy-policy">Privacy Policy</Link>
          <br></br>300 Sharks is not officially affiliated with The Ocean Cleanup project - we are just huge fans ❤️ 
          <br></br>THE OCEAN CLEANUP trademarks are registered and owned by The Ocean Cleanup Technologies B.V.
          <br></br>OpenSea trademarks are registered and owned by Ozone Networks, Inc
          </Container>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
